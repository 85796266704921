<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-bk-form-modal': true,
        'w-bk-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        class="pointer"
        :src="
          $voicer.isMobile
            ? require('~/assets/images/popupmobile.png')
            : require('~/assets/images/popupdesktop.png')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 368 : undefined"
        @click="
          handleOpen(
            'http://www.areyounet.com/runet/client/Reponse?i=683062815&s=9FE6B210'
          )
        "
      />
    </v-card>
  </v-dialog>
</template>

<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss">
.pointer:hover {
  cursor: pointer;
}
.headline {
  font-size: 15px;
}

.w-bk-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .body-1 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .text-alternate {
    color: #184a8b;
    text-align: center;
  }
}
</style>
