<template>
  <div class="w-block__pre-heading">
    <!-- pre-heading block -->
    <exc-dialog />
  </div>
</template>
<script>
import ExcDialog from '~/excentrics/components/ExcDialog'

export default {
  components: { ExcDialog },
}
</script>
